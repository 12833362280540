// import * as Type from '../constants/actionTypes'
// import moment from 'moment'
import { IO_API_SERVICE } from "../../services/ioApi";
import { sportFacilities } from "../../sportsFacilityMap";

export const fetchMoreVenues = (data, oldVenues = [], dispatch, venue = true) => {
   dispatch({
      type: "SWITCH_LOADER",
      payload: true
   });

   IO_API_SERVICE.fetchAllVenues(data, dispatch).then(response => {
      dispatch({
         type: "RATING_LOADER",
         payload: false
      });

      if (response?.data?.requestStatus === 1) {
         let tabsHeader = response?.data?.data?.tabHeadersMetadata;
         if (venue) {
            response = {
               ...response,
               data: {
                  ...response.data,
                  data: {
                     ...response.data.data,
                     venueList: [...oldVenues, ...response.data.data.venueList]
                  }
               }
            };
         } else {
            response = {
               ...response,
               data: {
                  ...response.data,
                  data: {
                     ...response.data.data,
                     exploreListing: [...oldVenues, ...response.data.data.exploreListing]
                  }
               }
            };
         }

         //  else {
         //    let sponsoredList = response?.data?.sponsored;
         //    sponsoredList = sponsoredList.map(sponsoredList => {
         //       sponsoredList.feature = true;
         //       return sponsoredList;
         //    });
         //    response = Object.assign({}, response, {
         //       data: {
         //          ...response.data,
         //          list: [...sponsoredList, ...response?.data?.list]
         //       }
         //    });
         // }

         dispatch({
            type: "SET_VENUE_LIST",
            payload: response.data
         });
         if (data?.page === 0) {
            dispatch({
               type: "SET_TAB_LIST",
               payload: { tab: data?.category, tabsHeader }
            });
         }

         dispatch({
            type: "SWITCH_LOADER",
            payload: false
         });

         // else {
         //    dispatch({
         //       type: "INFO_MODAL",
         //       payload: {
         //          open: true,
         //          data: response?.data?.message,
         //          text: "text-error",
         //          intent: "bg-error"
         //       }
         //    });
         // }
      } else {
         dispatch({
            type: "INFO_MODAL",
            payload: {
               open: true,
               data: "Something went wrong!",
               text: "text-error",
               intent: "bg-error"
            }
         });

         dispatch({
            type: "SWITCH_LOADER",
            payload: false
         });
      }
   });
};

export const searchVenues = (data, query, dispatch, venue = true) => {
   IO_API_SERVICE.searchEventsVenues(data, query).then(response => {
      let oldVenues = [];
      if (response?.data?.requestStatus === 1) {
         let tabsHeader = response?.data?.data?.tabHeadersMetadata;
         if (venue) {
            response = {
               ...response,
               data: {
                  ...response.data,
                  data: {
                     ...response.data.data,
                     venueList: [...oldVenues, ...response.data.data.venueList]
                  }
               }
            };
         } else {
            response = {
               ...response,
               data: {
                  ...response.data,
                  data: {
                     ...response.data.data,
                     exploreListing: [...oldVenues, ...response.data.data.exploreListing]
                  }
               }
            };
         }

         dispatch({
            type: "SET_VENUE_LIST",
            payload: response.data
         });

         dispatch({
            type: "SET_TAB_LIST",
            payload: { tab: data?.category, tabsHeader }
         });

         dispatch({
            type: "SWITCH_LOADER",
            payload: false
         });

         // else {
         //    dispatch({
         //       type: "INFO_MODAL",
         //       payload: {
         //          open: true,
         //          data: response?.data?.message,
         //          text: "text-error",
         //          intent: "bg-error"
         //       }
         //    });
         // }
      } else {
         dispatch({
            type: "INFO_MODAL",
            payload: {
               open: true,
               data: "Something went wrong!",
               text: "text-error",
               intent: "bg-error"
            }
         });

         dispatch({
            type: "SWITCH_LOADER",
            payload: false
         });
      }
   });
};

export const findSportsFacilities = sports => {
   const facilities = [];
   sports.forEach(sport => {
      const foundSport = sportFacilities.find(
         sportFacility => sportFacility.sport.toLowerCase() === sport.toLowerCase()
      );
      if (foundSport) {
         facilities.push(`${foundSport.sport[0].toUpperCase() + foundSport.sport.slice(1)}`);
      } else {
         facilities.push(sport[0].toUpperCase() + sport.slice(1));
      }
   });

   return facilities;
};

export const truncateString = string => {
   let str = string.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase());

   let words = str.split(" ");
   if (words.length <= 3) {
      return str;
   }

   let truncated = words.slice(0, 3).join(" ");
   if (truncated.includes(",")) {
      truncated = truncated.replace(",", "");
   }

   return `${truncated}...`;
};

export const fetchMetaData = key => {
   let response = null;

   return IO_API_SERVICE.fetchMetaData(key)
      .then(res => {
         response = { ...res.data };
         return response;
      })
      .catch(() => {
         response = { title: "", description: "" };
         return response;
      });
};
